import { render, staticRenderFns } from "./settings-screen.vue?vue&type=template&id=2350e0c6&scoped=true"
import script from "./settings-screen.vue?vue&type=script&lang=js"
export * from "./settings-screen.vue?vue&type=script&lang=js"
import style0 from "./settings-screen.vue?vue&type=style&index=0&id=2350e0c6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2350e0c6",
  null
  
)

export default component.exports