<template>
    <div>
      <transition name="modal">
        <nitrozen-dialog :title="title" ref="confirmation_dialog" @close="closeDialog"
          class="confirmation-dialog-container">
          <template slot="body">
            <div class="desc-text mb-20">{{ description }}</div>
            <div class="desc-text">Are you sure you want to proceed?</div>
            <div v-if="showInventoryResetCheckbox">
              <nitrozen-checkbox class="sync-button" v-model="resetInventorySync">
              <span class="dark-sm">{{ syncMessage }}</span>
            </nitrozen-checkbox>
            </div>
          </template>
          <template slot="footer">
            <nitrozen-button v-flat-btn class="proceed-btn" theme="secondary"
              @click="handleProceed()">Proceed</nitrozen-button>
            <nitrozen-button v-stroke-btn theme="secondary" @click="closeDialog()">Cancel</nitrozen-button>
          </template>
        </nitrozen-dialog>
      </transition>
    </div>
  </template>
    
  <script>
  import {
    NitrozenButton,
    NitrozenCheckBox,
    NitrozenDialog,
  } from "@gofynd/nitrozen-vue";
  import isEmpty from "lodash/isEmpty";
  export default {
    name: "confirmation-dialog",
    components: {
      "nitrozen-button": NitrozenButton,
      "nitrozen-dialog": NitrozenDialog,
      'nitrozen-checkbox': NitrozenCheckBox,
    },
    props: {
      title: {
        type: String,
        default: "Confirmation",
      },
      description: {
        type: String,
        default: "",
      },
      syncMessage: {
        type: String,
        default: "",
      },
      showInventoryResetCheckbox: {
        type: Boolean,
        default: false,
      }
    },
    data: function () {
      return {
        resetInventorySync: true,
      };
    },
    computed: {
    },
    mounted() {
    },
    methods: {
      isEmpty(obj) {
        return isEmpty(obj);
      },
      openConfirmationDialog() {
        this.$refs["confirmation_dialog"].open({
          width: "600px",
          height: "500px",
          showCloseButton: true,
          dismissible: false,
        });
        document.body.style.position = "";
        document.body.style.top = "";
      },
      handleProceed() {
        this.$emit("proceed", this.resetInventorySync);
        this.resetModalStyle();
      },
      closeDialog() {
        this.$emit("close");
        this.resetModalStyle();
      },
      resetModalStyle() {
        const scrollY = document.body.style.top;
        document.body.style.position = "";
        document.body.style.top = "";
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
      },
    },
  };
  </script>
    
  <style lang="less" scoped>
  .confirmation-dialog-container {
    :deep(.nitrozen-dialog-body) {
      overflow: auto;
    }
  
    :deep(.nitrozen-dialog) {
      height: auto !important;
    }
  
    :deep(.nitrozen-dialog-backdrop) {
      z-index: 10000000;
    }
  }
  
  .input {
    margin: 10px 0px;
  }
  
  .disable {
    opacity: 0.5;
  }
  
  .desc-text {
    font-size: 15px;
  }
  
  .mb-20 {
    margin-bottom: 20px;
  }
  
  .proceed-btn {
    margin-right: 10px;
  }
  </style>
  