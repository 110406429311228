<template>
  <div>
    <custom-breadcrumb
      :navigations="navs"
      class="breadcrumbs"
    ></custom-breadcrumb>

    <div class="setting-form">
      <div class="top-container" v-if="!credEmpty">
        <div class="top-left">
          <div class="settings-text">Settings</div>
          <p class="settings-desc">
            Enter Location code & turn on sync to make flow inventory through
            that location on the marketplace
          </p>
        </div>
        <div class="top-center" v-if="showSettings">
          <nitrozen-toggle v-model="isActive"></nitrozen-toggle>
          <span>Active</span>
        </div>
        <div class="top-right">
          <nitrozen-button
            v-flatBtn
            :disabled="disableSaveButton"
            class="act-btn"
            theme="secondary"
            v-if="showSettings"
            @click="onSave"
          >
            Save
          </nitrozen-button>
        </div>
      </div>
      <div class="center-container">
         <e-credentials
          :title="'Myntra PPMP Credentials'"
          :desc="'These credentials will help us connect to the marketplace to sync all your your Fynd Platform products and inventory to Myntra PPMP from time to time'"
          :mkpFormConfig="mkpFormConfig"
          :creds="creds"
          :visibility="visibility"
          :currentVisibility="currentVisibility"
          :credEmpty="credEmpty"
          :configError="configError"
          @handleSubmit="onSubmit($event)"
        />
        <div class="right-container" v-if="showSettings">
          <inv-comp
            :title="'Inventory Configurations'"
            :desc="' Enable/Disable sending Inventory/Price updates to a marketplace for all stores in a company'"
            :quantity="'Quantity Sync'"
            :price="'Price Sync'"
            :showSettings="true"
            :syncDefault="syncDefault"
          />

        </div>
        <div class="right-container" v-else>
          <div>
            <p class="common-head">How to get Myntra PPMP Credentials?</p>
            <no-ssr>
              <video controls class="video">
                <source :src="videoLink" />
              </video>
            </no-ssr>
          </div>
          <!-- Marketpalce inventory settings and manual trigger -->
        </div>
      </div>
      <div class="foot-container" v-if="!showSettings">
        <p class="common-head">Make your marketplace management seemless</p>
        <div class="foot-boxes">
          <div v-for="foot in footContent" :key="foot.title" class="foot-box">
            <div class="foot-icon">
              <inline-svg
                v-if="foot.icon"
                :src="foot.icon"
                class="feature-icon"
              ></inline-svg>
            </div>

            <div>
              <p class="foot-title">{{ foot.title }}</p>
              <p class="foot-desc">{{ foot.desc }}</p>
            </div>
            <!-- Marketpalce inventory settings and manual trigger -->
          </div>
        </div>
      </div>
      <loader class="loading" v-if="inProgress"></loader>
      <confirmation-dialog
          :title="confirmationTitle"
          :description="confirmationDesc"
          v-if="showDialog"
          ref="confirmation-dialog-ref"
          @close="closeConfirmationDialog"
          @proceed="onProceed"
          :showInventoryResetCheckbox="showInventoryResetCheckbox"
          :syncMessage="syncMessage"
        >
      </confirmation-dialog>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../node_modules/@gofynd/nitrozen-vue/dist/nitrozen.css";
@import "../less/theme.less";
@import "../less/media.less";
@import "../less/text.less";
@import "../less/color.less";
@import "../less/page-header.less";
@import "../less/variables.less";
// @import './../../../../node_modules/vue-tel-input/dist/vue-tel-input.css';
.breadcrumbs {
  margin: 24px;
  padding-left: 24px;
}
.common-head {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 12px;
  /* identical to box height, or 133% */

  /* Text/primary */

  color: #41434c;
}
p {
  margin: unset;
}
.amz-info {
  background-color: white;
  margin: 24px 0px;
  padding: 24px;
  .amz-helper {
    margin: 15px 0px 20px 0px;
  }
}
.amz-merchant-info {
  display: flex;
  .input-amz {
    width: 50%;
    margin-right: 24px;
  }
}
.fk-helper {
  line-height: 1.6;
  margin: 12px 0px 25px 0px;
}
.loading {
  z-index: 1000;
}
.sync-button {
  display: block;
  margin: 24px 0px 20px;
}
.date-helper {
  color: Red;
}
.capitialize {
  text-transform: capitalize;
}
.manual-inv-label {
  margin-top: 30px;
}
.manual-inv-diag {
  :deep(.nitrozen-dialog-body) {
    overflow: unset !important;
  }
}
.option {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  margin-left: 10px;
}
.tooltip {
  line-height: 1.5;
}
.btn-wrapper {
  margin-right: 20px;
}
.date-picker {
  margin-top: 20px;
  width: 250px;
  margin-left: 10px;
  :deep(label > span) {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 4px;
    display: block;
  }
}
.btns {
  margin-top: 20px;
  margin-left: 10px;
}
.setting-form {
  position: relative;
  height: 80vh;
  background: #ffffff;
  border-radius: 12px;
  padding: 24px;
  margin: 24px;
  //   border: 1px solid #e0e0e0;
}
.top-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  .top-left {
    width: 80%;
    .settings-text {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      /* identical to box height, or 140% */

      color: #41434c;
      margin-bottom: 6px;
    }
    .settings-desc {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      /* identical to box height, or 142% */

      /* Text/secondary */

      color: #666666;
      margin-bottom: 24px;
    }
  }
  .top-center {
    display: flex;
    margin-left: auto;
  }
  .top-right {
    margin-left: 24px;
  }
}
.center-container {
  display: flex;
  // justify-content: space-around;
  // @media @mobile {
  //     display: block;
  // }
}
.consent-div {
  color: @Mako;
  font-size: 12px;
  // padding-top: 12px;
  position: absolute;
  bottom: 0;
  line-height: 20px;
  font-weight: 100;
}
.left-container {
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  width: 60%;
  padding: 24px;
  background: #ffffff;
  .desc-text {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
  }
  .act-btn {
    min-width: 117px;
    margin-top: 35px;
  }
}
.input {
  margin: 20px 0px 0px;
}
.right-container {
  width: 40%;
  margin-left: 24px;
  padding: 24px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  background: #ffffff;
  .customer-care-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    .nitrozen-form-input {
      flex: 1;
    }
    .phone-input-text {
      height: 40px;
      border: 1px solid #eee;
      width: 100%;
    }
    .copy {
      cursor: pointer;
      margin-left: 12px;
    }
  }
  .modal-items {
    width: 90%;
    border-radius: @BorderRadius;
    border: 1px solid #e4e5e6;
    height: 272px;
  }
  .video {
    border-radius: 4px;
    width: 100%;
    height: auto;
  }
  .image {
    width: 100%;
    .iframe {
      width: 100%;
      height: auto;
    }
  }
}
.plan-container {
  margin: 24px;
  padding: 24px;
  background: #ffffff;
  border-radius: 12px;

  display: flex;
  justify-content: space-between;

  .plan-text {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height, or 117% */

    color: #41434c;
  }
  .plan-desc {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    /* Text/secondary */

    color: #666666;
  }
  .plan-upgrade {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    /* colour/text/button */

    color: #2e31be;
  }
}
.foot-container {
  margin-top: 24px;
  background: #ffffff;
  // border-radius: 12px;
  padding: 24px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  // .foot-head {
  //   width: 100%;
  //   font-family: Inter;
  //   font-style: normal;
  //   font-weight: 600;
  //   font-size: 18px;
  //   line-height: 24px;
  //   /* identical to box height, or 133% */

  //   /* Text/primary */

  //   color: #41434c;
  // }
  .foot-boxes {
    display: flex;
    // justify-content: space-between;
    .foot-box {
      width: 25%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      margin-right: 24px;
    }
    .foot-icon {
      margin: 10px;
    }
    .foot-title {
      margin: 12px 0px 6px;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      /* identical to box height, or 160% */

      /* Text/primary */

      color: #41434c;
    }
    .foot-desc {
      // margin: unset;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 17px;
      /* identical to box height, or 142% */

      /* Text/primary */

      color: #41434c;
    }
  }
}
.video-container {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.playWrapper {
  display: flex;
  margin: 0px 20px 0px 0px;
  cursor: pointer;
  .overlay {
    border-radius: 6px;
    position: relative;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    // justify-content: center;
    align-items: center;
    width: 280px;
    height: 160px;
    img {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 120px;
    }
  }
}
.mkp-helper {
  line-height: 1.4;
  text-align: justify;
  text-indent: 40px;
  margin-bottom: 10px;
}
.steps {
  padding-left: 30px;
  list-style: disc;
  line-height: 1.5;
  margin-top: 10px;
  span {
    color: RoyalBlue;
    cursor: pointer;
  }
}
.error {
  color: Red;
  padding-top: 20px;
}
.darker-sm {
  font-weight: 600;
  font-size: 16px;
}
.cl-RoyalBlue {
  color: #2e31be;
}
.password-input {
  .password-icon {
    float: right;
    margin-right: 7px;
    margin-top: -28px;
    position: relative;
    cursor: pointer;
    z-index: 2;
    height: 24px;
    width: 24px;
  }
  //   @supports (not (-webkit-hyphens: none)) and (not (-moz-appearance: none)) {
  //     .password-icon {
  //       display: none;
  //     }
  //   }

  .password-icon-eye {
    margin-top: -28px;
    margin-right: 6px;
  }
  .password-icon-eye-cancel {
    margin-top: -32px;
    margin-right: 7px;
  }
}
</style>

<script>
import MainService from "@/services/main.service";

import {
  decryptWithAES,

} from "@/helper/utils.js";
import loader from "@/components/common/adm-loader";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import InlineSvg from "@/components/common/inline-svg";
import {ExtensionCredentials,ExtensionInvConf} from "marketplace-utilities/web";
import breadCrumb from "@/components/common/breadcrumbs.vue";
import confirmationDialog from "./confirmation-dialog.vue";
import {
  // NitrozenInput,
  // NitrozenError,
  NitrozenToggleBtn,
  flatBtn,
  strokeBtn,
  NitrozenButton,
} from "@gofynd/nitrozen-vue";

const MKP_FORM_CONFIG = [
  {
    label: "Merchant ID",
    type: "text",
    value: "merchant_id",
    error: "",
  },
  {
    label: "Secret Key",
    type: "password",
    value: "secret_key",
    error: "",
  },
];

const NAVS = [
  {
    title: "Home",
    link: "",
  },
  { title: "Settings" },
];

const FootContent = [
  {
    title: "Manage Products",
    desc: "Sync fynd platform products to Myntra PPMP",
    icon: "product_store",
  },
  {
    title: "Sync Selling Locations",
    desc: "One click syncing of selling locations to Myntra PPMP",
    icon: "store_deployment",
  },
  {
    title: "Analytics & Reports",
    desc: "Tools to track your marketplace success",
    icon: "logs",
  },
];

export default {
  name: "marketplace-config-form",
  components: {
    "nitrozen-button": NitrozenButton, // eslint-disable-line no-unused-vars
    "nitrozen-toggle": NitrozenToggleBtn,
    "inline-svg": InlineSvg,
    "custom-breadcrumb": breadCrumb,
    "e-credentials":ExtensionCredentials,
    "inv-comp":ExtensionInvConf,
    "confirmation-dialog": confirmationDialog,
    loader,
  },
  directives: {
    flatBtn,
    strokeBtn,
  },
  props: {
    credEmpty: {
      type: Boolean,
      default: false,
    },
    registeredMarketplaces: {},
    showSettings: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    // a computed getter
    isCredEntered: function () {
      // `this` points to the vm instance
      this.mkpFormConfig.forEach((form) => {
        if (isEmpty(this.creds) || !this.creds[form.value]) {
          return false;
        }
      });
      return true;
    },
  },
  data: function () {
    return {
      navs: [],
      disableSaveButton:false,
      showOptions: false,
      videoLink:
        "https://hdn-1.addsale.com/addsale/products/videos/item/free/original/4AR9gT6yb-Test-product.mp4",
      mkpFormConfig: MKP_FORM_CONFIG,
      syncDefault: {},
      configError: "",
      inProgress: false,
      // updateType: '',
      mkpTitle: "Myntra PPMP",
      cleanCredState: {},
      cleanSyncState: {},
      configuration: {},
      visibility: {
        makeVisible: "password-eye",
        makeInvisible: "password-eye-cancel",
      },
      currentVisibility: "password-eye",
      iframeLoad: false,
      footContent: FootContent,
      isActive: false,
      companyDefaultStatus:false,
      showDialog: false,
      showInventoryResetCheckbox: false,
      confirmationTitle: "Confirmation",
      syncMessage:"Also, Reset the inventory of mapped articles on the marketplace.",
      confirmationDesc:"Making the extension as Inactive will disconnect all the syncs from the marketplace.",
      creds: {},
    };
  },
  mounted() {
    this.navs = NAVS;
    this.initializeMkpForm();
    this.checkCompanyStatus();
  },
  methods: {
    initializeMkpForm() {
      if (!this.credEmpty) {
        this.inProgress = true;
        let configPromise = MainService.getCompanyConfig();
        let statusPromise = MainService.getCompanyStatus();
        let syncPromise = MainService.getInventorySyncConfig();
        Promise.all([configPromise, statusPromise, syncPromise])
          // syncPromise
          .then((resp) => {
            this.$set(this, "creds", resp[0].data);
            this.$set(this, "isActive", resp[1].data.is_active);
            this.$set(this, "companyDefaultStatus", resp[1].data.is_active);
            this.$set(this, "syncDefault", resp[2].data);
            this.creds.secret_key = decryptWithAES(this.creds.secret_key,"fynd")
            this.cleanCredState = {
              ...this.creds,
            };
            this.cleanSyncState = {
              sync: this.syncDefault,
            };
            this.$forceUpdate();
          })
          .finally(() => {
            this.inProgress = false;
          });
      }
    },
    checkCompanyStatus() {
      return MainService.getCompanyStatus().then(({ data }) => {
          if(data.currentStatus == 'enabled' || data.currentStatus == 'disabled'){
            this.disableSaveButton = true    
          }
      });
    },
    onSave() {
      if(this.isActive !== this.companyDefaultStatus && this.syncDefault.quantity) {
        this.showInventoryResetCheckbox = !this.isActive;
        this.confirmationDesc = this.isActive
          ? "Making the extension as Active will connect all the syncs to the marketplace."
          :  "Making the extension as Inactive will disconnect all the syncs from the marketplace.";
        this.showDialog = true;
        setTimeout(() => {
            this.$refs[
                "confirmation-dialog-ref"
            ].openConfirmationDialog();
        }, 0);
      } else{
        this.onProceed(false)
      }  
    },
    closeConfirmationDialog() {
      this.showDialog = false;
    },
    onProceed(resetInventorySync) {
      this.showDialog = false;
      this.inProgess = true;
      let validateCred = true;
      let settingPromise = this.createUpdateMkpConfig(validateCred);

      return settingPromise
          .then((data /*eslint-disable-line no-unused-vars*/) => {
            return this.updateInventorySyncConfig();

          })
          .then(() => {
            return this.updateMarketplaceStatus(resetInventorySync);
          })
          .then(() => {
            this.$snackbar.global.showSuccess(
              `${this.mkpTitle} settings saved sucessfully`
            );
          })
          .catch((msg) => {
            this.$router.push({ name: "main" });
            this.$snackbar.global.showError(
              `Failed to save ${this.mkpTitle} settings${
                msg ? " : " + msg : ""
              }`
            );
          })
          .finally(() => {
            this.inProgess = false;
            this.$router.push({ name: "main" });
          });
    },
    updateMarketplaceStatus(isInventoryReset) {
      this.inProgress = true;
      // to stop screen abruput movement
      let payload = { is_active: this.isActive, isInventoryReset };
      MainService.updateMarketplaceStatus(payload)
        .then(() => {
          this.inProgress = false;
        })
        .catch((err) => {
          this.inProgress = false;
          this.isActive = this.newActiveStatus;
          let msg =
            (err &&
              err.response &&
              err.response.data &&
              err.response.data.message) ||
            (err && err.message);
          this.$snackbar.global.showError(
            `${this.mkpTitle} marketplace status update failed${
              msg ? " : " + msg : ""
            }`
          );
        });
    },
    isEmpty(obj) {
      return isEmpty(obj);
    },
    onSubmit() {
      if (this.validateForm()) {
        this.inProgess = true;
        let settingPromise = this.createUpdateMkpConfig(true);

        return (
          settingPromise
            // .then((data /*eslint-disable-line no-unused-vars*/) => {
            //   if (this.credEmpty) {
            //     EXT.destroy();
            //     // window.location.reload();
            //     this.$router.go();
            //     // this.$set(this, "is_active", true);
            //     // this.$set(this, "credEmpty", false);
            //     // this.$set(this, "showSettings", true);
            //     return;
            //   }
            // })
            .then(() => {
              this.$router.go();
              this.$snackbar.global.showSuccess(
                `${this.mkpTitle} settings saved sucessfully`
              );
              this.$forceUpdate();
            })
            .catch((msg) => {
              console.log("error is", msg);
              this.$snackbar.global.showError(
                `Failed to save ${this.mkpTitle} settings${
                  msg ? " : " + msg : ""
                }`
              );
            })
            .finally(() => {
              this.inProgess = false;
            })
        );
      }
    },
    createUpdateMkpConfig(validateCred) {
      this.configError = "";
      let payload = cloneDeep(this.creds);
      let configPromise;
      let cleanCredState = cloneDeep(this.cleanCredState);
      if (isEqual(cleanCredState, payload)) {
        return Promise.resolve({});
      } else {
        configPromise = MainService.updateMarktplaceConfig(payload, {
          validate_cred: validateCred || "true",
        });
      }

      return configPromise
        .then(({ data }) => {
          this.inProgress = false;
          this.cleanCredState = this.cleanCredState = {
            ...data.configuration,
          };
          return data;
        })
        .catch((err) => {
          this.$snackbar.global.showError(
            "Failed to save marketplace credentials"
          );
          this.inProgress = false;
          this.configError = "Marketplace configuration invalid";
          return Promise.reject(err);
        });
    },
    updateInventorySyncConfig() {
      let payload = {
        quantity: this.syncDefault.quantity,
        price: this.syncDefault.price,
      };
      return MainService.updateInventorySyncConfig(payload, {
        validate_cred: "false",
      }).then(() => {
      });
    },
    validateForm() {
      this.configError = "";
      if (isEmpty(this.creds)) {
        this.$snackbar.global.showError("Please enter valid credentials");
        return false;
      }
      let formValid = true;

      this.mkpFormConfig.forEach((form) => {
        if (!this.creds[form.value]) {
          form.error = `Please enter ${form.label}`;
          formValid = false;
        } else {
          form.error = "";
        }
      });
      return formValid;
    },
    isFormDirty() {
      // check in mkp settings form
      let currentState = (this.cleanCredState = {
        ...this.creds,
        sync: this.syncDefault,
      });
      return !isEqual(this.cleanCredState, currentState);
    },
    isCredsExists() {
      return isEmpty(this.creds);
    },
    toggleView() {
      this.mkpFormConfig.forEach((form) => {
        if (form.label == "Secret Key") {
          if (form.type === "password") {
            form.type = "text";
            this.currentVisibility = this.visibility.makeInvisible;
          } else {
            form.type = "password";
            this.currentVisibility = this.visibility.makeVisible;
          }
        }
      });
    },
    onIframeLoad() {
      this.iframeLoad = true;
    },
  },
  destroyed() {
    console.log("######### destroyed called");
  },
};
</script>
